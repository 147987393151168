<template>
  <b-sidebar
    id="add-new-organization-sidebar"
    :visible="isAddNewOrganizationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-organization-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Organization</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div class="progressbar">
            <div class="progress" id="progress"></div>
            <div
              class="progressbarstep progressbarstepactive"
              data-title="Organization"
            ></div>
            <div class="progressbarstep" data-title="Address"></div>
            <div class="progressbarstep" data-title="Contact"></div>
          </div>

          <div class="bform bformactive">
            <b-form-checkbox
              id="checkbox-1"
              v-model="cloud_channel_addition"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              Cloud Channel Addition
            </b-form-checkbox>

            <validation-provider
              #default="validationContext"
              name="region"
              rules="required"
            >
              <b-form-group label-for="region_type">
                <template v-slot:label>
                  Region <span class="required">*</span>
                </template>
                <b-form-select
                  v-if="checkstatus == false"
                  id="region_type"
                  v-model="organizationData.region_id"
                  :options="regionTypes"
                  :state="getValidationState(validationContext)"
                  value-field="id"
                  text-field="name"
                  v-on:change="getCurrency"
                />
                <b-form-input
                  id="region_type"
                  v-model="regionName"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="cloud channel id"
              rules="required"
              v-if="cloud_channel_addition == false"
            >
              <b-form-group label-for="cloud_customer_id">
                <template v-slot:label>
                  Cloud Channel Id <span class="required">*</span>
                </template>
                <b-form-input
                  id="cloud_customer_id"
                  v-model="organizationData.cloud_customer_id"
                  :state="getValidationState(validationContext)"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="cloud_customer_id"
                  v-model="organizationData.cloud_customer_id"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
              v-if="cloud_channel_addition == true"
            >
              <b-form-group label-for="name">
                <template v-slot:label>
                  Organization Name <span class="required">*</span>
                </template>

                <b-form-input
                  id="name"
                  v-model="organizationData.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="domain"
              rules="required|url"
              v-if="cloud_channel_addition == true"
            >
              <b-form-group label-for="domain">
                <template v-slot:label>
                  Domain <span class="required">*</span>
                </template>
                <b-form-input
                  id="domain"
                  v-model="organizationData.domain"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="currency"
              rules="required"
              v-if="checkstatus == false && cloud_channel_addition == true"
            >
              <b-form-group label-for="currency_type">
                <template v-slot:label>
                  Currency <span class="required">*</span>
                </template>
                <b-form-select
                  id="currency_type"
                  v-model="organizationData.currency_id"
                  :options="currency"
                  :state="getValidationState(validationContext)"
                  value-field="id"
                  text-field="name"
                  v-if="organizationData.region_id !== ''"
                />
                <b-form-select
                  id="currency_type"
                  v-b-tooltip.hover
                  title="Please select region"
                  v-if="organizationData.region_id == ''"
                  v-model="organizationData.currency_id"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="currency"
              rules="required"
              v-if="checkstatus == true && cloud_channel_addition == false"
            >
              <b-form-group label-for="currency_type">
                <template v-slot:label>
                  Currency <span class="required">*</span>
                </template>
                <b-form-select
                  id="currency_type"
                  v-model="organizationData.currency_id"
                  :options="currency"
                  :state="getValidationState(validationContext)"
                  value-field="id"
                  text-field="name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Account Manager"
              rules="required"
              v-if="checkstatus == false && cloud_channel_addition == true"
            >
              <b-form-group label-for="account_manager">
                <template v-slot:label>
                  Account Manager <span class="required">*</span>
                </template>
                <b-form-select
                  v-if="userData.role_name !== 'ACCOUNT_MANAGER'"
                  id="account_manager"
                  v-model="organizationData.account_manager"
                  :options="accountManager"
                  :state="getValidationState(validationContext)"
                  value-field="uid"
                  text-field="display_name"
                />
                <b-form-input
                  v-model="organizationData.account_manager"
                  readonly
                  v-if="userData.role_name == 'ACCOUNT_MANAGER'"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Account Manager"
              rules="required"
              v-if="checkstatus == true && cloud_channel_addition == false"
            >
              <b-form-group label-for="account_manager">
                <template v-slot:label>
                  Account Manager <span class="required">*</span>
                </template>
                <b-form-select
                  v-if="userData.role_name !== 'ACCOUNT_MANAGER'"
                  id="account_manager"
                  v-model="organizationData.account_manager"
                  :options="accountManager"
                  :state="getValidationState(validationContext)"
                  value-field="uid"
                  text-field="display_name"
                />
                <b-form-input
                  v-model="organizationData.account_manager"
                  readonly
                  v-if="userData.role_name == 'ACCOUNT_MANAGER'"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="support tiers"
              rules="required"
              v-if="checkstatus == false && cloud_channel_addition == true"
            >
              <b-form-group label-for="support_tiers">
                <template v-slot:label>
                  Support Tiers <span class="required">*</span>
                </template>
                <b-form-select
                  id="support_tiers"
                  v-model="organizationData.support_tier"
                  :options="supportTiers"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="support tiers"
              rules="required"
              v-if="checkstatus == true && cloud_channel_addition == false"
            >
              <b-form-group label-for="support_tiers">
                <template v-slot:label>
                  Support Tiers <span class="required">*</span>
                </template>
                <b-form-select
                  id="support_tiers"
                  v-model="organizationData.support_tier"
                  :options="supportTiers"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="google cloud discount"
              rules="required|between:0,15"
              v-if="
                checkstatus == false &&
                cloud_channel_addition == true &&
                userData.role_name == 'GLOBAL_ADMIN'
              "
            >
              <b-form-group label-for="google_cloud_discount">
                <template v-slot:label> Google Cloud Discount </template>
                <b-input-group>
                  <b-form-input
                    id="google_cloud_discount"
                    v-model="organizationData.google_cloud_discount"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="google cloud discount"
              rules="required|between:0,15"
              v-if="
                checkstatus == true &&
                cloud_channel_addition == false &&
                userData.role_name == 'GLOBAL_ADMIN'
              "
            >
              <b-form-group label-for="google_cloud_discount">
                <template v-slot:label> Google Cloud Discount </template>
                <b-input-group>
                  <b-form-input
                    id="google_cloud_discount"
                    v-model="organizationData.google_cloud_discount"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="google workspace discount"
              rules="required|between:0,15"
              v-if="
                checkstatus == false &&
                cloud_channel_addition == true &&
                userData.role_name == 'GLOBAL_ADMIN'
              "
            >
              <b-form-group label-for="google_workspace_discount">
                <template v-slot:label> Google Workspace Discount </template>
                <b-input-group>
                  <b-form-input
                    id="google_workspace_discount"
                    v-model="organizationData.google_workspace_discount"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="google workspace discount"
              rules="required|between:0,15"
              v-if="
                checkstatus == true &&
                cloud_channel_addition == false &&
                userData.role_name == 'GLOBAL_ADMIN'
              "
            >
              <b-form-group label-for="google_workspace_discount">
                <template v-slot:label> Google Workspace Discount </template>
                <b-input-group>
                  <b-form-input
                    id="google_workspace_discount"
                    v-model="organizationData.google_workspace_discount"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col
                class="d-flex justify-content-start"
                v-if="cloud_channel_addition == false"
              >
                <b-button
                  variant="primary"
                  @click="getCloudChannelInfo"
                  :disabled="
                    organizationData.region_id == '' ||
                    organizationData.cloud_customer_id == '' ||
                    checkstatus == true
                  "
                >
                  <b-spinner
                    small
                    label="Text Centered"
                    v-if="loading"
                  ></b-spinner
                  ><span> Get Details</span></b-button
                >
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  @click="nextStep"
                  :disabled="
                    cloud_channel_addition
                      ? organizationData.name == '' ||
                        organizationData.domain == '' ||
                        organizationData.region_id == '' ||
                        organizationData.currency_id == '' ||
                        organizationData.account_manager == '' ||
                        organizationData.support_tier == ''
                      : organizationData.currency_id == '' ||
                        organizationData.account_manager == '' ||
                        organizationData.support_tier == ''
                  "
                >
                  Next</b-button
                >
              </b-col>
            </b-row>
          </div>

          <div class="bform">
            <validation-provider
              #default="validationContext"
              name="country"
              rules="required"
            >
              <b-form-group label-for="country_code">
                <template v-slot:label>
                  Country <span class="required">*</span>
                </template>
                <b-form-select
                  id="country_code"
                  v-model="organizationData.country_code"
                  :options="countries"
                  :state="getValidationState(validationContext)"
                  value-field="country_code"
                  text-field="name"
                  v-on:change="getState"
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="country_code"
                  v-model="countryName"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label-for="state_code">
              <template v-slot:label> State </template>
              <b-form-select
                id="state_code"
                v-b-tooltip.hover
                title="Please select country"
                v-if="
                  organizationData.country_code == '' && checkstatus == false
                "
                v-model="organizationData.state_code"
              ></b-form-select>

              <b-form-select
                id="state_code"
                v-if="
                  organizationData.country_code !== '' &&
                  state.length !== 0 &&
                  checkstatus == false
                "
                v-model="organizationData.state_code"
                :options="state"
                value-field="state_code"
                text-field="name"
              />

              <b-form-input
                id="state_code"
                v-if="
                  organizationData.country_code !== '' &&
                  state.length == 0 &&
                  checkstatus == false
                "
                v-model="organizationData.state_code"
                trim
              />

              <b-form-input
                id="state_code"
                v-model="stateName"
                v-if="checkstatus == true"
                readonly
              />
            </b-form-group>

            <validation-provider
              #default="validationContext"
              name="city"
              rules="required"
            >
              <b-form-group label-for="city">
                <template v-slot:label>
                  City <span class="required">*</span>
                </template>
                <b-form-input
                  id="city"
                  v-model="organizationData.city"
                  :state="getValidationState(validationContext)"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="city"
                  v-model="organizationData.city"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="postal code"
              rules="required"
            >
              <b-form-group label-for="postal_code">
                <template v-slot:label>
                  Postal Code <span class="required">*</span>
                </template>
                <b-form-input
                  id="postal_code"
                  v-model="organizationData.postal_code"
                  :state="getValidationState(validationContext)"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="postal_code"
                  v-model="organizationData.postal_code"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="language code"
              rules="required"
            >
              <b-form-group label-for="language_code">
                <template v-slot:label>
                  Language Code <span class="required">*</span>
                </template>
                <b-form-select
                  id="language_code"
                  v-model="organizationData.language_code"
                  :options="languageCodes"
                  :state="getValidationState(validationContext)"
                  value-field="code"
                  text-field="name"
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="language_code"
                  v-model="languageCodeName"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="address_lines1"
              rules="required"
            >
              <b-form-group label-for="address_lines1">
                <template v-slot:label>
                  Address <span class="required">*</span>
                </template>
                <b-form-input
                  id="address_lines1"
                  v-model="organizationData.address_lines1"
                  :state="getValidationState(validationContext)"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="address_lines1"
                  v-model="organizationData.address_lines1"
                  v-if="checkstatus == true"
                  readonly
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label-for="address_lines2">
              <b-form-input
                id="address_lines2"
                v-model="organizationData.address_lines2"
                trim
                v-if="checkstatus == false"
              />
              <b-form-input
                id="address_lines2"
                v-model="organizationData.address_lines2"
                v-if="checkstatus == true"
                readonly
              />
            </b-form-group>

            <b-form-group label-for="address_lines3">
              <b-form-input
                id="address_lines3"
                v-model="organizationData.address_lines3"
                trim
                v-if="checkstatus == false"
              />
              <b-form-input
                id="address_lines3"
                v-model="organizationData.address_lines3"
                v-if="checkstatus == true"
                readonly
              />
            </b-form-group>

            <b-row>
              <b-col class="d-flex justify-content-start">
                <b-button variant="primary" @click="prevStep"> Back</b-button>
              </b-col>

              <b-col class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  @click="nextStep"
                  :disabled="
                    organizationData.country_code == '' ||
                    organizationData.city == '' ||
                    organizationData.postal_code == '' ||
                    organizationData.language_code == '' ||
                    organizationData.address_lines1 == ''
                  "
                >
                  Next</b-button
                >
              </b-col>
            </b-row>
          </div>

          <div class="bform">
            <validation-provider
              #default="validationContext"
              name="first name"
              rules="required"
            >
              <b-form-group label-for="first_name">
                <template v-slot:label>
                  First Name <span class="required">*</span>
                </template>
                <b-form-input
                  id="first_name"
                  v-model="organizationData.first_name"
                  :state="getValidationState(validationContext)"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="first_name"
                  v-model="organizationData.first_name"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="last name "
              rules="required"
            >
              <b-form-group label-for="last_name">
                <template v-slot:label>
                  Last Name <span class="required">*</span>
                </template>
                <b-form-input
                  id="last_name"
                  v-model="organizationData.last_name"
                  :state="getValidationState(validationContext)"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="last_name"
                  v-model="organizationData.last_name"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col class="pr-0">
                <validation-provider
                  #default="validationContext"
                  name="email"
                  rules="required"
                >
                  <b-form-group label-for="email">
                    <template v-slot:label>
                      Email <span class="required">*</span>
                    </template>
                    <b-form-input
                      id="email"
                      v-model="email_orgname"
                      autocomplete="off"
                      :state="
                        getValidationState(validationContext) && domainName
                      "
                      trim
                      v-if="checkstatus == false"
                    />
                    <b-form-input
                      id="email"
                      v-model="email_orgname"
                      v-if="checkstatus == true"
                      readonly
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback :state="domainName">
                      This email is part of a reserved domain. Please enter a
                      different email address.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col class="pl-0 domain-style">
                <validation-provider
                  #default="validationContext"
                  name="domain name"
                  rules="required"
                >
                  <b-input-group prepend="@">
                    <b-form-input
                      id="domain"
                      v-model="organizationData.domain"
                      readonly
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>

            <validation-provider
              #default="validationContext"
              name="alternate email"
              rules="email"
            >
              <b-form-group label-for="alternate_email">
                <template v-slot:label>
                  Alternate Email
                  <feather-icon
                    v-b-tooltip.hover
                    title="Email and alternate email could not be same"
                    icon="InfoIcon"
                    size="12"
                    class="feather-info"
                  />
                </template>
                <b-form-input
                  id="alternate_email"
                  v-model="organizationData.alternate_email"
                  :state="alternateEmailValidation"
                  trim
                  v-if="checkstatus == false"
                />
                <b-form-input
                  id="alternate_email"
                  v-model="organizationData.alternate_email"
                  v-if="checkstatus == true"
                  readonly
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="alternateEmailValidation">
                  Email and alternate email could not be same
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label-for="phone_number" label="Phone">
              <b-form-input
                id="phone_number"
                v-model="organizationData.phone_number"
                trim
                v-if="checkstatus == false"
              />
              <b-form-input
                id="phone_number"
                v-model="organizationData.phone_number"
                v-if="checkstatus == true"
                readonly
              />
            </b-form-group>

            <b-form-group label-for="role">
              <template v-slot:label>
                Role <span class="required">*</span>
              </template>
              <b-form-input id="role" v-model="role" readonly />
            </b-form-group>

            <!-- passwod -->
            <validation-provider
              #default="validationContext"
              name="password"
              rules="required|min:6"
              vid="password"
            >
              <b-form-group label-for="password">
                <template v-slot:label>
                  Password <span class="required">*</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="password"
                    v-model="organizationData.password"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldType"
                    trim
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- confirm passwod -->
            <validation-provider
              #default="validationContext"
              name="confirm password"
              rules="required|confirmed:password"
            >
              <b-form-group label-for="confirm-password">
                <template v-slot:label>
                  Confirm Password <span class="required">*</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="confirm-password"
                    :type="confirmPasswordFieldType"
                    v-model="organizationData.confirm_password"
                    :state="getValidationState(validationContext)"
                    trim
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="confirmpasswordToggleIcon"
                      @click="toggleConfirmPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <b-button variant="primary" @click="prevStep">Back</b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-5 mr-2"
              :disabled="
                organizationData.first_name == '' ||
                organizationData.last_name == '' ||
                email_orgname == '' ||
                organizationData.password == '' ||
                organizationData.confirm_password == '' ||
                isLoading
              "
            >
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  label="Text Centered"
                  v-if="isLoading"
                ></b-spinner>
                <span class="button-title" @click="onSubmit">Add</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormInvalidFeedback,
  BButton,
  BLink,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import {
  required,
  alphaNum,
  email,
  url,
  regex,
  confirmed,
  between,
} from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
// import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@axios";
export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BLink,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "isAddNewOrganizationSidebarActive",
    event: "update:is-add-new-organization-sidebar-active",
  },
  props: {
    isAddNewOrganizationSidebarActive: {
      type: Boolean,
      required: true,
    },
    regionTypes: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    languageCodes: {
      type: Array,
      required: true,
    },
    accountManager: {
      type: Array,
      required: true,
    },
    supportTiers: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      url,
      regex,
      confirmed,
      between,
      // countries,
      togglePassword: false,
      state: [],
      currency: [],
    };
  },
  watch: {
    cloud_channel_addition(newValue) {
      if (newValue) {
        this.checkstatus = false;
        this.resetForm();
        this.setAccountManager();
      }
    },
    isAddNewOrganizationSidebarActive() {
      this.checkstatus = false;
      this.formstepnum = 0;
      this.updateForm();
      this.updateProgressBar();
      this.resetForm();
      this.setAccountManager();
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    confirmpasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    organizationNames() {
      return store.getters["app-organization/organizationsList"];
    },
    domainName() {
      return this.email_orgname.includes("@") ? false : true;
    },
    alternateEmailValidation() {
      if (this.organizationData.alternate_email) {
        return this.organizationData.alternate_email.includes(
          this.organizationData.domain
        )
          ? false
          : true;
      }
    },
  },
  methods: {
    getCurrency() {
      this.organizationData.currency_id = "";
      axios
        .get(`/currency/region/${this.organizationData.region_id}`)
        .then((response) => {
          const currencyName = [];
          for (const [key, value] of Object.entries(response.data)) {
            currencyName.push(value);
          }
          this.currency = currencyName;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getState() {
      this.organizationData.state_code = "";
      axios
        .get(`/region/countries/${this.organizationData.country_code}/states`)
        .then((response) => {
          const stateName = [];
          for (const [key, value] of Object.entries(response.data.states)) {
            stateName.push(value);
          }
          this.state = stateName;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    nextStep() {
      this.formstepnum++;
      this.updateForm();
      this.updateProgressBar();
    },
    prevStep() {
      this.formstepnum--;
      this.updateForm();
      this.updateProgressBar();
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const userData = JSON.parse(localStorage.getItem("userData"));

    let cloud_channel_addition = ref(false);
    let isLoading = ref(false);
    let loading = ref(false);
    let checkstatus = ref(false);
    let email_orgname = ref("");
    let regionName = ref("");
    let countryName = ref("");
    let stateName = ref("");
    let languageCodeName = ref("");
    let formstepnum = ref(0);
    let role = ref("ORG_ADMIN");

    const blankOrganizationData = {
      name: "",
      cloud_customer_id: "",
      domain: "",
      region_id: "",
      currency_id: "",
      account_manager: "",
      support_tier: "",
      google_cloud_discount: "0",
      google_workspace_discount: "0",
      country_code: "",
      state_code: "",
      city: "",
      postal_code: "",
      language_code: "",
      address_lines1: "",
      address_lines2: "",
      address_lines3: "",
      first_name: "",
      last_name: "",
      email: "",
      alternate_email: "",
      phone_number: "",
      role_id: "",
      password: "",
      confirm_password: "",
    };

    const organizationData = ref(
      JSON.parse(JSON.stringify(blankOrganizationData))
    );

    const resetorganizationData = () => {
      organizationData.value = JSON.parse(
        JSON.stringify(blankOrganizationData)
      );
    };

    const setAccountManager = () => {
      if (userData.role_name == "ACCOUNT_MANAGER") {
        organizationData.value.account_manager = userData.display_name;
      } else {
        organizationData.value.account_manager = "";
      }
    };

    setAccountManager();

    const updateForm = () => {
      const formstep = document.querySelectorAll(".bform");
      formstep.forEach((formstep) => {
        formstep.classList.remove("bformactive");
      });
      formstep[formstepnum.value].classList.add("bformactive");
    };
    const updateProgressBar = () => {
      const progress = document.getElementById("progress");
      const progresstep = document.querySelectorAll(".progressbarstep");
      progresstep.forEach((progresstep, idx) => {
        if (idx < formstepnum.value + 1) {
          progresstep.classList.add("progressbarstepactive");
        } else {
          progresstep.classList.remove("progressbarstepactive");
        }
      });
      const progressstepactive = document.querySelectorAll(
        ".progressbarstepactive"
      );
      progress.style.width =
        ([progressstepactive.length - 1] / [progresstep.length - 1]) * 100 +
        "%";
    };

    const onSubmit = () => {
      isLoading.value = true;

      for (let value of Object.values(props.roles)) {
        if (value.role_name == role.value) {
          organizationData.value.role_id = value.role_id;
        }
      }

      if (userData.role_name !== "GLOBAL_ADMIN") {
        organizationData.value.google_cloud_discount = undefined;
        organizationData.value.google_workspace_discount = undefined;
      }

      if (userData.role_name == "ACCOUNT_MANAGER") {
        organizationData.value.account_manager = userData.id;
      }

      organizationData.value.email =
        email_orgname.value + "@" + organizationData.value.domain;

      const data = {
        cloudChannelAddition: cloud_channel_addition.value,
        organizationData: organizationData.value,
      };
      store
        .dispatch("app-organization/addOrganization", data)
        .then(() => {
          isLoading.value = false;
          checkstatus.value = false;
          formstepnum.value = 0;
          updateForm();
          updateProgressBar();
          emit("refetch-data");
          emit("update:is-add-new-organization-sidebar-active", false);
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Organization added successfully`,
            },
          });
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Organization could not added`,
            },
          });
        });
    };

    const getCloudChannelInfo = () => {
      loading.value = true;
      axios
        .get(
          `/organization/${organizationData.value.region_id}/${organizationData.value.cloud_customer_id}`
        )
        .then((response) => {
          loading.value = false;
          if (!response.data.error) {
            checkstatus.value = true;
            organizationData.value = response.data;
            organizationData.value.currency_id = "";
            if (userData.role_name == "ACCOUNT_MANAGER") {
              organizationData.value.account_manager = userData.display_name;
            } else {
              organizationData.value.account_manager = "";
            }
            if (userData.role_name !== "GLOBAL_ADMIN") {
              organizationData.value.google_cloud_discount = undefined;
              organizationData.value.google_workspace_discount = undefined;
            } else {
              organizationData.value.google_cloud_discount = "0";
              organizationData.value.google_workspace_discount = "0";
            }
            organizationData.value.support_tier = "";
            organizationData.value.password = "";
            organizationData.value.confirm_password = "";

            email_orgname.value = response.data.email.split("@")[0];
            regionName.value = nameCheck(
              response.data.region_id,
              props.regionTypes,
              "id"
            );
            countryName.value = nameCheck(
              response.data.country_code,
              props.countries,
              "country_code"
            );
            languageCodeName.value = nameCheck(
              response.data.language_code,
              props.languageCodes,
              "code"
            );
            axios
              .get(`/region/countries/${response.data.country_code}/states`)
              .then((response) => {
                for (const value of Object.values(response.data.states)) {
                  if (value.state_code == organizationData.value.state_code) {
                    stateName.value = value.name;
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((err) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data.error.message,
            },
          });
        });
    };

    const nameCheck = (regionId, regionTypes, Id) => {
      for (let value of Object.values(regionTypes)) {
        if (value[Id] == regionId) {
          return value.name;
        }
      }
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetorganizationData
    );
    return {
      userData,
      role,
      isLoading,
      loading,
      organizationData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      cloud_channel_addition,
      email_orgname,
      checkstatus,
      regionName,
      countryName,
      stateName,
      languageCodeName,
      formstepnum,
      getCloudChannelInfo,
      setAccountManager,
      updateForm,
      updateProgressBar,
    };
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

@import "@core/scss/vue/libs/vue-select.scss";
#add-new-organization-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.bform {
  display: none;
  transform-origin: top;
  animation: animate 0.5s;
}
.bformactive {
  display: block;
}
.progressbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  margin: 0rem 3rem 3rem 3rem;
}
.progressbar::before,
.progress {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 3px;
  width: 100%;
  background-color: $progressbar-bg;
  z-index: -1;
}
.progressbarstep {
  width: 2.1875rem;
  height: 2.1875rem;
  background-color: $progressbar-bg;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
}
.progressbarstep::before {
  counter-increment: step;
  content: counter(step);
}
.progressbarstep::after {
  width: 60px;
  margin-left: 13px;
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.75rem);
  font-size: 0.85rem;
  color: $progressbar-color;
  font-weight: 500;
}
.progress {
  background-color: var(--primary);
  width: 0%;
}
.progressbarstepactive {
  background-color: var(--primary);
  color: $progressbar-active;
  font-weight: 900;
}
@keyframes animate {
  from {
    transform: scale(1, 0);
    opacity: 0;
  }
  to {
    transform: (1, 1);
    opacity: 1;
  }
}
.custom-control-label {
  padding-bottom: 1rem;
  font-size: 12px !important;
}
.required {
  color: $required;
  font-size: 1rem;
}
.domain-style {
  margin-top: 1.7rem !important;
}
.support-hours-message {
  font-size: 0.857rem;
}
</style>
