<template>
  <div>
    <organization-list-add-new
      :is-add-new-organization-sidebar-active.sync="
        isAddNewOrganizationSidebarActive
      "
      :region-types="regionTypes"
      :countries="countries"
      :language-codes="languageCodes"
      :account-manager="accountManager"
      :support-tiers="supportTiers"
      :roles="roles"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="org-filter">
      <div class="m-2">
        <!-- Table Top -->

        <b-row>
          <!-- Per Page -->
          <b-col cols="12" lg="5" md="12" sm="12" xs="12">
            <b-row>
              <b-col
                class="col-auto d-flex align-items-center justify-content-start mb-1 mb-md-0 p-1 px-md-1 p-md-0"
              >
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                />
              </b-col>

              <b-col
                lg="6"
                md="6"
                sm="6"
                xs="12"
                v-if="
                  userData.default_region_id ==
                    'f06c8247-3b89-4ab5-8bb7-3734bf931c53' &&
                  (userData.role_name == 'GLOBAL_ADMIN' ||
                    userData.role_name == 'ACCOUNT_MANAGER')
                "
              >
                <v-select
                  v-model="selectedCurrency"
                  :options="currencyTypes"
                  label="code"
                  placeholder="Currency Type"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col cols="12" lg="7" md="12" sm="12" xs="12">
            <b-row class="d-flex justify-content-end">
              <b-col lg="4" md="4" sm="4" xs="12" class="p-1 px-md-1 p-md-0">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </b-col>
              <b-col class="p-1 px-md-1 p-md-0 pl-md-0 col-auto">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="isSearch = true"
                >
                  <span class="text-nowrap">Search</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="isAddNewOrganizationSidebarActive = true"
                >
                  <span class="text-nowrap">Add Organization</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- <b-row class="mt-1">
          <b-col lg="3" md="3" sm="3" xs="12" class="p-1">
            <v-select
              v-model="regionSelect"
              :options="regionTypes"
              placeholder="Select Region"
              label="name"
              @input="getCurrency"
            />
          </b-col>

          <b-col lg="3" md="3" sm="3" xs="12" class="p-1">
            <v-select
              v-model="currencySelect"
              :options="currencyTypes"
              placeholder="Select Currency"
              label="name"
            />
          </b-col> -->
        <!-- <b-col lg="3" md="3" sm="3" xs="12" v-if="userData.role_name == 'GLOBAL_ADMIN'" class="p-1">
            <v-select v-model="organziationSelect" :options="organization" placeholder="Select Organziation" label="name"
              @input="getUser" />
          </b-col>
          <b-col lg="3" md="3" sm="3" xs="12" v-if="userData.role_name == 'GLOBAL_ADMIN'" class="p-1">
            <v-select v-model="userSelect" :options="user" placeholder="Select User" label="first_name"
              :disabled="organziationSelect == null" />
          </b-col>
          <b-col lg="3" md="3" sm="3" xs="12" v-if="userData.role_name == 'ORG_ADMIN'" class="p-1">
            <v-select v-model="userSelect" :options="user" placeholder="Select User" label="first_name" />
          </b-col> -->
        <!-- </b-row> -->
      </div>
    </b-card>

    <b-card>
      <div class="organizations">
        <b-table
          v-show="!isSearchLoading"
          ref="refOrganizationListTable"
          class="position-relative"
          :items="fetchOrganizations"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @row-clicked="viewOrganizations"
        >
          <template #cell(name)="data">
            <div class="text-nowrap customtd">
              <span class="align-text-top text-capitalize">{{
                data.item.name
              }}</span>
            </div>
          </template>
          <template #cell(email)="data">
            <div class="customtd" v-if="data.item.email">
              <span class="align-text-top">{{
                data.item.email.length > 50
                  ? data.item.email.substring(0, 50) + "..."
                  : data.item.email
              }}</span>
            </div>
          </template>

          <template #cell(domain)="data">
            <div class="text-nowrap customtd">
              <span class="align-text-top">{{ data.item.domain }}</span>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              @click="
                (e) => {
                  e.stopPropagation();
                  Edit(data);
                }
              "
              size="20"
              class="feather feather-info"
            />
            <feather-icon
              icon="TrashIcon"
              @click="
                (e) => {
                  e.stopPropagation();
                  showModal(data.item);
                }
              "
              size="20"
              class="feather feather-danger"
              v-if="userData.role_name !== 'ACCOUNT_MANAGER'"
            />
          </template>
        </b-table>
      </div>
      <b-modal
        ref="my-modal"
        no-close-on-backdrop
        hide-footer
        title="Confirmation"
      >
        <div class="d-block text-center" v-if="activeItem">
          <h4 class="mb-0">
            Are you sure you want to delete {{ activeItem.name }}
          </h4>
        </div>
        <div class="d-flex justify-content-end" v-if="activeItem">
          <b-button
            class="mt-1 mr-1"
            variant="primary"
            @click="
              (e) => {
                e.stopPropagation();
                deleteItem(activeItem);
                hideModal();
              }
            "
            >Ok</b-button
          >
          <b-button class="mt-1" @click="hideModal">Cancel</b-button>
        </div>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrganizations"
              :per-page="perPage"
              first-number
              limit="3"
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useOrganizationsList from "./useOrganizationsList";
import organizationStoreModule from "../organizationStoreModule";
import OrganizationListAddNew from "./OrganizationListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
export default {
  components: {
    OrganizationListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  data() {
    return {
      currencyOptions: [],
      activeItem: null,
    };
  },
  beforeMount() {
    this.getCurrency();
  },
  watch: {
    searchQuery(newValue) {
      if (newValue == "") {
        this.refetchData();
      }
    },
    selectedCurrency(newValue) {
      if (newValue) {
        store.commit("app/CURRENCY_TYPE", newValue.code);
        this.refetchData();
      }
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.searchQuery = "";
        this.currentPage = 1;
        if (
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
        ) {
          const regionCurrencyData = JSON.parse(
            localStorage.getItem("regionCurrencyData")
          );
          for (const [key, value] of Object.entries(
            regionCurrencyData.regions_details
          )) {
            this.currencyTypes = value.currencies;
          }
        }
        this.refetchData();
      },
      immediate: false,
    },
  },
  methods: {
    activate(id) {
      this.perPage = id;
    },
    getCurrency() {
      if (
        this.userData.default_region_id ==
        "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
      ) {
        const regionCurrencyData = JSON.parse(
          localStorage.getItem("regionCurrencyData")
        );
        for (const [key, value] of Object.entries(
          regionCurrencyData.regions_details
        )) {
          this.currencyTypes = value.currencies;
        }
      }
    },
    showModal(item) {
      this.activeItem = item;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    Detail(data) {
      this.$router.push({
        name: "apps-organizations-view",
        params: { id: data.item.id },
      });
    },
    Edit(data) {
      this.$router.push({
        name: "apps-organizations-edit",
        params: { id: data.item.id },
      });
    },
    viewOrganizations(item) {
      this.$router.push("/apps/organizations/view/" + item.id);
    },
  },
  setup(props, { emit }) {
    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    const toast = useToast();
    const userData = JSON.parse(localStorage.getItem("userData"));
    let currencyTypes = ref([]);
    let regionTypes = ref([]);
    let countries = ref([]);
    let languageCodes = ref([]);
    let accountManager = ref([]);
    let supportTiers = ref([]);
    let roles = ref([]);

    const {
      fetchOrganizations,
      regionSelect,
      selectedCurrency,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,
      refetchData,
      isSearchLoading,
      isSearch,
      // UI
      resolveOrganizationRoleVariant,
    } = useOrganizationsList();
    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });
    store
      .dispatch("app-organization/fetchRegionTypes")
      .then((response) => {
        regionTypes.value = response.data;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching region types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchCountries")
      .then((response) => {
        countries.value = response.data.countries;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching countries",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchLanguageCodes")
      .then((response) => {
        languageCodes.value = response.data.language_codes;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching language codes",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchAccountManager")
      .then((response) => {
        accountManager.value = response.data;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching account manager",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    store
      .dispatch("app-organization/fetchSupportTiers")
      .then((response) => {
        supportTiers.value = response.data.support_tiers;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching support tiers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    const data = {
      company_id: userData.company_id,
    };

    store
      .dispatch("app-organization/fetchRoles", data)
      .then((response) => {
        roles.value = response.data.roles;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching support tiers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    store
      .dispatch("app-organization/fetchSupportTiers")
      .then((response) => {
        supportTiers.value = response.data.support_tiers;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching support tiers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    const deleteItem = (item) => {
      if (item) {
        store
          .dispatch("app-organization/deleteOrganization", item.id)
          .then(() => {
            refetchData();
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success `,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Organization deleted successfully`,
              },
            });
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: `Organization could not deleted`,
              },
            });
          });
      }
    };
    const isAddNewOrganizationSidebarActive = ref(false);
    return {
      // Sidebar
      isAddNewOrganizationSidebarActive,
      fetchOrganizations,
      userData,
      regionSelect,
      selectedCurrency,
      currencyTypes,
      regionTypes,
      countries,
      languageCodes,
      accountManager,
      supportTiers,
      roles,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,
      refetchData,
      deleteItem,
      isSearchLoading,
      isSearch,
      // Filter
      avatarText,
      // UI
      resolveOrganizationRoleVariant,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.feather {
  cursor: pointer;
  margin: 0 1rem 0 0rem;
}
.customtd {
  margin: 0.6rem 0rem;
}
.org-filter {
  margin-bottom: 2rem;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.organizations table tr {
  cursor: pointer;
}
</style>
